<template>
  <div class="job-schedule-team-header d-flex">
    <h3 class="m-0 text-uppercase font-weight-700 color-custom-blue">
      <v-layout>
        <v-flex>
          Team
          <v-tooltip v-if="false" top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-model="all"
                  :value="true"
                  hide-details
                  :disabled="scheduleLater"
                  color="cyan"
                  class="mt-0 pt-0"
                  v-on:change="getAvailableTeam"
                >
                </v-checkbox>
              </div>
            </template>
            <span>Get All Available Engineers</span>
          </v-tooltip>
        </v-flex>
        <v-flex class="position-ralative">
          <v-menu
            v-if="availableTeam.length"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                right
                small
                :disabled="scheduleLater"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in availableTeam"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assignedTeam"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span>
                          </v-tooltip>
                          <v-list-item-subtitle
                            v-if="lodash.isEmpty(team.designation) == false"
                            >{{ team.designation.value }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
            </v-list>
          </v-menu>
          <v-btn
            v-else
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            right
            small
            :loading="teamLoading"
            :disabled="scheduleLater || teamLoading"
            v-on:click="getAvailableTeam"
          >
            <v-icon class="mr-2">mdi-account-group</v-icon>Get Available
          </v-btn>
        </v-flex>
      </v-layout>
    </h3>
    <template>
      <SamePostalCode
        :dialog="managePostalCodeDialog"
        v-on:close-dialog="managePostalCodeDialog = false"
      ></SamePostalCode>
    </template>
  </div>
</template>

<style></style>

<script>
import { mapGetters } from "vuex";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
import SamePostalCode from "@/view/pages/partials/Same-Postal-Code.vue";
import {
  ClearEventBus,
  ErrorEventBus,
  InitializeError,
  WeekEventBus,
} from "@/core/lib/message.lib";
import { isEmpty } from "lodash";
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import { map } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-schedule-team-header",
  data() {
    return {
      managePostalCodeDialog: false,
      visit_postal: null,
      scheduleLater: true,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      teamLoading: false,
      force: false,
      all: true,
      skills: [],
      assignedTeam: new Array(),
      availableTeam: new Array(),
      timeout: null,
      timeoutLimit: 1000,
      remarkData: [],
      preEngineers: [],
    };
  },
  watch: {
    assignedTeam: {
      deep: true,
      immediate: true,
      handler() {
        this.emitEngineers();
      },
    },
    getTeam: {
      deep: true,
      immediate: true,
      handler(param) {
        this.initUpdate(param);
      },
    },
  },
  components: {
    SamePostalCode,
  },
  methods: {
    initUpdate(param) {
      this.preEngineers = [];
      if (param && param.length) {
        this.preEngineers = this.lodash.map(param, "id");
      }
    },
    emitEngineers() {
      JobScheduleTeamEventBus.$emit("update:schedule-team", {
        availableTeam: this.availableTeam,
        assignedTeam: this.assignedTeam,
      });
    },
    getAvailableTeam() {
      const _this = this;
      _this.availableTeam = [];
      _this.assignedTeam = [];

      if (_this.scheduleLater) {
        return false;
      }

      ClearEventBus.$emit("clear:success");

      if (
        moment(_this.startDate + " " + _this.startTime).isAfter(
          _this.endDate + " " + _this.endTime
        )
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Start date & time must before End date & time.")
        );
        return false;
      }

      if (!_this.all && _this.lodash.isEmpty(_this.skills)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select job type.")
        );
        return false;
      }

      if (_this.lodash.isEmpty(_this.startDate)) {
        return false;
      }

      _this.teamLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: _this.startDate,
            start_time: _this.startTime,
            end_date: _this.endDate,
            end_time: _this.endTime,
            force: +_this.force,
            all: +_this.all,
            skills: _this.skills,
            postal_code: this.visit_postal,
            prev_engineers: this.preEngineers,
          },
        })
        .then(({ data }) => {
          let availableVisit = [];
          availableVisit = map(data.available_visits, (row) => row.barcode);
          if (
            !isEmpty(availableVisit) &&
            availableVisit.toString() !== _this.remarkData.toString()
          ) {
            this.managePostalCodeDialog = true;
            _this.remarkData = availableVisit;
          }
          _this.availableTeam = data.engineers;

          let prev_selected_eng = _this.lodash.filter(data.engineers, {
            prev_selected: true,
          });

          if (prev_selected_eng && prev_selected_eng.length) {
            _this.assignedTeam = _this.lodash.map(prev_selected_eng, "id");
          }

          JobScheduleTeamEventBus.$emit("update:schedule-text", data);
          WeekEventBus.$emit("visit-Postal-code", { getOptionNew: data });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.teamLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("get:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("remove:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("update:skills");*/
  },
  mounted() {
    JobScheduleTeamEventBus.$emit("get:skills");
    WeekEventBus.$on("visit-newposts-code", (param) => {
      // console.log(param);
      this.visit_postal = param.postalcodenew;
      // console.log(this.visit_postal, "newdate");
      //  console.log(this.visit_postal, "hello");
    });
  },
  computed: {
    ...mapGetters(["getTeam"]),
  },
  destroyed() {
    WeekEventBus.$off("visit-newposts-code");
    JobScheduleTeamEventBus.$off("get:schedule-team");
    JobScheduleTeamEventBus.$off("remove:schedule-team");
    JobScheduleTeamEventBus.$off("update:schedule-later");
    JobScheduleTeamEventBus.$off("update:skills");
  },
  created() {
    const _this = this;
    JobScheduleTeamEventBus.$on("get:schedule-team", (param) => {
      try {
        _this.startDate = param.start_date
          ? moment(param.start_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        _this.startTime = param.start_time
          ? moment(param.start_time, ["h:mm A"]).format("HH:mm")
          : moment().startOf("day").format("HH:mm");
        _this.endDate = param.end_date
          ? moment(param.end_date).format("YYYY-MM-DD")
          : moment(_this.startDate).format("YYYY-MM-DD");
        _this.endTime = param.end_time
          ? moment(param.end_time, ["h:mm A"]).format("HH:mm")
          : moment().endOf("day").format("HH:mm");
        _this.availableTeam = new Array();
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.getAvailableTeam();
        }, _this.timeoutLimit);
        if (param.schedule_later === 1) {
          _this.assignedTeam = new Array();
        }
      } catch (error) {
        _this.logError(error);
      }
    });

    JobScheduleTeamEventBus.$on("remove:schedule-team", ({ index }) => {
      _this.assignedTeam.splice(index, 1);
    });

    JobScheduleTeamEventBus.$on("update:schedule-later", (param) => {
      if (param) {
        _this.assignedTeam = new Array();
      }
      _this.scheduleLater = param;
      if (_this.scheduleLater) {
        _this.availableTeam = [];
        _this.assignedTeam = [];
      }
    });

    JobScheduleTeamEventBus.$on("update:skills", (param) => {
      if (_this.lodash.isEmpty(param) === false) {
        _this.all = false;
      } else {
        _this.all = true;
      }
      _this.skills = param;
      _this.getAvailableTeam();
    });
  },
};
</script>
